<template>
<div style="background-color: #141414 !important; " class="fullscreen-element">
  <BRow class=" justify-content-center align-items-center h-100" style="   ; overflow: hidden">
  <BCol md="8" lg="6" xl="5">

    <BCard   bg-variant="dark" shadow-lg>

      <BCol class="text-center">
        <img   width="100" height="100" src="@/assets/images/logo-transparent.png"/>
      </BCol>

      <BCardBody class="p-4 text-center"  >

        <div class="mt-4 pt-2">
          <h4>Well done!</h4>
          <p class="text-muted mx-4 my-2 text-center"  style="direction: rtl">
            شكرا لاشتراكك! سوف تتلقى رابط المحتوى عبر رسالة نصية قصيرة من 3368. لإلغاء الاشتراك في أي وقت أرسل F7 إلى 3368.
          </p>
          <!--                <b-button class="mt-4"   to="/"  style="color:#9FDB48" >-->
          <!--              Back to Home-->
          <!--                </b-button>-->
        </div>
      </BCardBody>
    </BCard>
  </BCol>
</BRow>
</div>



</template>
<style>

</style>
<script>

export default {
  name: 'ThankYouPage'

}
</script>
<style>
.fullscreen-element {
  width: 100vw;  /* 100% of viewport width */
  height: 100vh; /* 100% of viewport height */
 }
</style>
